<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <el-form-item label="缺勤日期" required>
        <el-date-picker
          v-model="time"
          class="el-popper DatepickerTime"
          type="daterange"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :disabled="disabled"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="公司" prop="companyId">
        <el-select v-model="dataForm.companyId" filterable :disabled="disabled">
          <el-option
            v-for="(item, index) in companyList"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-radio-group v-model="dataForm.status" :disabled="disabled">
          <el-radio :label="0">加班</el-radio>
          <el-radio :label="1">放假</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="备注" prop="remarks">
        <el-input
          v-model="dataForm.remarks"
          :disabled="disabled"
          placeholder="备注"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      disabled: false,
      visible: false,
      companyList: [],
      dataForm: {
        id: 0,
        specialStartDay: '',
        specialEndDay: '',
        companyId: '',
        status: '',
        remarks: '',
      },
      time: [],
      dataRule: {
        specialStartDay: [
          { required: true, message: '开始日期不能为空', trigger: 'blur' },
        ],
        specialEndDay: [
          { required: true, message: '结束日期不能为空', trigger: 'blur' },
        ],
        companyId: [
          { required: true, message: '公司不能为空', trigger: 'blur' },
        ],
        status: [{ required: true, message: '状态不能为空', trigger: 'blur' }],
      },
    };
  },

  created() {
    this.getCompany();
  },

  methods: {
    init(id, disabled) {
      this.disabled = disabled;
      this.dataForm.id = id || '';
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (this.dataForm.id) {
          this.$http({
            url: `/tc/companyspecialday/info/${this.dataForm.id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm = { ...data.companyspecialday };
              this.time = [
                data.companyspecialday.specialStartDay,
                data.companyspecialday.specialEndDay,
              ];
            }
          });
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      if (this.time?.length) {
        this.dataForm.specialStartDay = this.time[0];
        this.dataForm.specialEndDay = this.time[1];
      }
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/tc/companyspecialday/${
              !this.dataForm.id ? 'save' : 'update'
            }`,
            method: 'post',
            data: this.dataForm,
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('refreshDataList');
            }
          });
        }
      });
    },

    //获得公司下拉框
    getCompany() {
      this.$http({
        url: '/tc/company/combo',
        method: 'get',
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.companyList = data.list;
        } else {
          this.companyList = [];
        }
      });
    },
  },
};
</script>
